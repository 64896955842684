<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Practitioner</v-toolbar-title
      >

      <v-spacer />
      <v-btn
        class="ml-5"
        :loading="practitionerLoading"
        @click="isUpdating ? update() : submit()"
      >
        Update Practitioner
      </v-btn>
      <v-btn class="ml-5 error" @click="showConfirmModal = true">
        <v-icon color="white">
          {{ icons.mdiDelete }}
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-form
      class="practitioner-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.name"
            class="input__outlined--regular"
            label="Practitioner Name"
            outlined
            :error-messages="form.$getError('name')"
            @input="form.$clearError('name')"
          />
          <v-text-field
            v-model.trim="form.specialisation"
            class="input__outlined--regular"
            label="Specialisation"
            outlined
            :error-messages="form.$getError('specialisation')"
            @input="form.$clearError('specialisation')"
          />
        </v-col>
        <v-col cols="6">
          <ContentEditor
            v-model="form.introduction"
            class="content-editor"
            :class="{ 'has-error': form.$getError('introduction') }"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('avatar') }"
            @click="uploadThumbnail('avatar')"
            v-if="!checkAvatarThumbNail(form)"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary"> Upload Avatar </span>
            </div>
          </div>
          <v-hover>
            <template v-slot:default="{ hover }">
              <div
                class="upload-container pointer"
                @click="uploadThumbnail('avatar')"
                v-if="checkAvatarThumbNail(form)"
              >
                <v-img
                  :src="form.avatar.url ? form.avatar.url : form.avatar[0].url"
                  alt="File Upload Placeholder"
                  height="270px"
                  width="auto"
                />

                <v-fade-transition>
                  <v-overlay v-if="hover" absolute>
                    <div class="upload-label mt-3">
                      <span variant="primary"> Upload Avatar </span>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </div>
            </template>
          </v-hover>
          <!-- // -->
          <div
            class="upload-container pointer mt-5"
            :class="{ 'has-error': form.$getError('introduction_video') }"
            @click="uploadThumbnail('introduction_video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary"> Upload Introduction Video </span>
            </div>

            <v-overlay :absolute="true" :value="uplodaLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>
          <div
            class="upload-container pointer mt-5"
            @click="uploadThumbnail('introduction_video')"
            v-if="checkIVideoThumbNail(form)"
          >
            <video controls :src="checkIVideoThumbNail(form)">
              Your browser does not support the video tag.
            </video>
          </div>
        </v-col>
        <v-col cols="6" :style="{ position: 'relative' }">
          <v-overlay :value="practitionerGalleryLoading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('gallery') }"
            @click="uploadThumbnail('gallery')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary"> Upload Gallery </span>
            </div>
          </div>

          <v-row>
            <v-col
              v-for="(photo, index) in gallery"
              :key="index"
              cols="4"
              class="my-5 image-container"
            >
              <v-img
                :src="photo.url"
                height="150"
                contain
                class="grey darken-4"
              />
              <v-btn
                fab
                class="radius__button text-none remove-img error"
                x-small
                depressed
                @click="removeImage(photo)"
              >
                <v-icon small color="white">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      :singleMedia="false"
      @startUpload="startUpload"
    ></MediaUploader>

    <ConfirmModal
      title="Delete Practitioner"
      message="Do you want to remove this Practitioner?"
      v-model="showConfirmModal"
      :loading="practitionerLoading"
      @cancel="showConfirmModal = false"
      @confirm="remove()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ContentEditor from '@/components/ContentEditor'
import { mdiDelete, mdiClose, mdiDotsVertical } from '@mdi/js'
import { objectToFormData } from '@/utils/jsonToFormData'
import MediaUploader from '@/components/MediaUploader'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'Practitioner',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ContentEditor,
    MediaUploader,
    ConfirmModal,
  },
  computed: {
    ...mapState('practitioner', {
      form: (state) => state.practitionerForm,
      practitionerLoading: (state) => state.practitionerLoading,
      practitionerGalleryLoading: (state) => state.practitionerGalleryLoading,
    }),
  },
  data() {
    return {
      icons: {
        mdiDelete,
        mdiClose,
        mdiDotsVertical,
      },
      showConfirmModal: false,
      imageState: null,
      gallery: [],
      mediaType: null,
      uplodaLoader: false,
    }
  },
  mounted() {
    this.form.$reset()
    this.getPractitioner({ id: this.$route.params.id })
    this.getPractitionersGallery({ id: this.$route.params.id }).then(() => {
      this.gallery = this.form.gallery
    })
  },
  methods: {
    ...mapActions({
      getPractitioner: 'practitioner/getPractitioner',
      getPractitionersGallery: 'practitioner/getPractitionersGallery',
      updatePractitioner: 'practitioner/updatePractitioner',
      deletePractitioner: 'practitioner/deletePractitioner',
      uploadPractitionerGallery: 'practitioner/uploadPractitionerGallery',
      deletePractitionerGallery: 'practitioner/deletePractitionerGallery',
    }),
    isUpdating() {
      return !this.$route.name.includes('create')
    },
    update() {
      delete this.form.gallery
      const { avatar, introduction_video, ...practitioner } = this.form.$data()
      const form = objectToFormData(practitioner)
      if (avatar) {
        form.append('avatar', avatar[0]?.file)
      }
      if (introduction_video) {
        form.append('introduction_video', introduction_video[0]?.file)
      }

      this.updatePractitioner({ id: this.$route.params.id, data: form })
    },
    uploadThumbnail(state) {
      if (state === 'introduction_video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    startUpload() {
      this.uplodaLoader = true
    },
    onMediaLoad(data) {
      switch (this.imageState) {
        case 'avatar':
          this.form.avatar = [
            {
              file: data.file.get('file'),
              url: data.url,
            },
          ]
          break
        case 'introduction_video':
          this.form.introduction_video = []
          this.form.introduction_video = [
            {
              file: data.file.get('file'),
              url: data.url,
            },
          ]
          this.uplodaLoader = false
          break
        case 'gallery':
          const form = new FormData()
          form.append('photos[]', data.file.get('file'))
          this.uploadPractitionerGallery({
            data: form,
            id: this.$route.params.id,
          })
          break
      }
    },
    removeImage(gallery) {
      this.deletePractitionerGallery({
        id: this.$route.params.id,
        galleryId: gallery.id,
      }).then(() => {
        this.gallery = this.form.gallery
      })
    },
    submit() {},
    remove() {
      this.deletePractitioner(this.$route.params.id).then(() => {
        this.$router.push({ name: 'practitioner' })
      })
    },
    checkAvatarThumbNail(image) {
      return image?.avatar?.[0]?.url || image?.avatar?.url
    },
    checkIVideoThumbNail(image) {
      return (
        image?.introduction_video?.[0]?.url || image?.introduction_video?.url
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.image-container {
  max-height: 140px;
  width: auto;
  position: relative;
}

.remove-img {
  position: absolute;
  top: 0;
  right: 0;
}

.content-editor {
  position: relative;
  &.has-error {
    border: 2px solid #fa4856;
  }
}

.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: 300px;
    width: 100%;
  }
}
</style>
