<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="center">
        <v-col cols="3"><h3>Categories</h3></v-col>
        <v-col cols="9">
          <v-switch v-model="openAll" label="Expand all"></v-switch>
        </v-col>
      </v-row>
    </v-card-title>
    <span v-if="form.$getError('categories')" class="error--text ml-3">{{
      form.$getError('categories')[0]
    }}</span>
    <v-treeview
      v-if="categories.length !== 0"
      v-model="selection"
      :items="categories"
      selectable
      return-object
      :open-all="openAll"
      :key="treeViewKey"
    />
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  props: ['items', 'selections'],
  data: () => ({
    selection: [],
    openAll: false,
    treeViewKey: 0,
  }),
  watch: {
    selections(value) {
      if (value.length !== 0) {
        this.selection = value
      }
    },
    selection(value) {
      this.setEducationCategories(value)
    },
    openAll(value) {
      this.treeViewKey += 1
    },
  },
  methods: {
    ...mapMutations({
      setEducationCategories: 'education/setEducationCategories',
    }),
  },
  computed: {
    categories() {
      return this.items.map((category) => {
        let item = {
          id: category.id,
          name: category.label,
        }

        if (category.sub_categories) {
          item.children = category.sub_categories.map((subCategory) => {
            let child = {
              id: subCategory.id,
              name: subCategory.label,
            }

            if (subCategory.sub_sub_categories) {
              child.children = subCategory.sub_sub_categories.map(
                (subSubCategory) => {
                  let grandChild = {
                    id: subSubCategory.id,
                    name: subSubCategory.label,
                  }

                  return grandChild
                }
              )
            }

            return child
          })
        }

        return item
      })
    },
    ...mapState('education', {
      form: (state) => state.educationForm,
    }),
  },
}
</script>
