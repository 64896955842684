<template>
  <div
    v-if="exercise.loading"
    class="w-full d-flex justify-center align-center"
    style="height: 100vh"
  >
    <v-progress-circular
      indeterminate
      :size="50"
      color="primary"
    ></v-progress-circular>
  </div>

  <div v-else>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title>
        <h4 class="poppins-regular font-normal text-sm">
          <router-link to="/exercise" class="text-decoration-none"
            ><span class="text-primary">Exercises</span></router-link
          >
          <span class="text-black"> / {{ exercise.data.title }}</span>
        </h4>
      </v-toolbar-title>
    </v-app-bar>

    <div>
      <div class="pb-8">
        <v-row class="justify-end mt-4 mb-8">
          <v-btn
            class="mr-4"
            outlined
            color="primary"
            large
            @click="submit"
            :loading="submitting"
            :disabled="submitting"
          >
            <span class="text-sm font-semibold">Update Exercise</span>
          </v-btn>
          <v-btn outlined color="primary" large @click="$router.back()">
            <span class="text-sm font-semibold">Cancel</span>
          </v-btn>
        </v-row>

        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model.trim="form.title"
              :error-messages="form.$getError('title')"
              class="input__outlined--regular"
              label="Exercise Title"
              outlined
              @input="form.$clearError('title')"
            />
            <CategorySelectBox
              :items="exerciseCategories"
              :selections="form.categories"
              @updateSelection="form.categories = $event"
              :errors="form.$getError('categories')"
            />
          </v-col>
          <v-col cols="3">
            <v-switch
              v-model="form.is_free"
              label="Free"
              class="mb-8"
              inset
              hide-details
            />
            <thumbnail-uploader
              v-if="!form.thumb_nail_from_s3"
              v-model="form.thumbnail"
              :media="exercise.data.thumb_nail"
              :errors="form.$getError('thumb_nail_id')"
              @input="form.$clearError('thumb_nail_id')"
            />
            <p class="text-center" v-if="!form.thumb_nail_from_s3">or</p>
            <v-text-field
              v-model.trim="form.thumb_nail_from_s3"
              :error-messages="form.$getError('thumb_nail_from_s3')"
              class="input__outlined--regular"
              label="Thumbnail from AWS"
              outlined
              @input="form.$clearError('thumb_nail_from_s3')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <file-uploader
              v-if="!form.video_from_s3"
              v-model="form.video"
              label="Introduction Video"
              :initial-preview-url="form.video?.url"
              accept="video/mp4,video/x-m4v,video/*"
              :errors="form.$getError('video_id')?.[0]"
            />
            <span class="error--text ml-3 text-caption">{{
              form.$getError('video_id')?.[0]
            }}</span>
            <p class="text-center" v-if="!form.video_from_s3">or</p>
            <v-text-field
              v-model.trim="form.video_from_s3"
              :error-messages="form.$getError('video_from_s3')"
              class="input__outlined--regular"
              label="Video from AWS"
              outlined
              @input="form.$clearError('video_from_s3')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              <p class="text-tertiary text-base">Description</p>

              <ContentEditor
                v-model="form.content"
                :errors="form.$getError('content')"
                class="content-editor"
                @input="form.$clearError('content')"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="py-8" style="border-top: 1px solid #e6e6e9">
        <v-card
          outlined
          class="pa-5"
          :style="{
            border: form.$getError('alternative_exercises')?.[0]
              ? '2px solid #fa4856'
              : '',
          }"
        >
          <div class="d-flex justify-space-between align-center mb-3">
            <p>Alternative Exercise</p>
            <v-btn
              outlined
              color="primary"
              large
              @click="showAltExerciseForm = true"
            >
              <span class="text-sm font-semibold">Add</span>
            </v-btn>
          </div>

          <v-card
            flat
            outlined
            class="mb-3"
            v-if="form.alternative_exercises?.length > 0"
          >
            <v-list-item-group>
              <v-list-item
                v-for="(altExercise, index) in form.alternative_exercises"
                :key="index"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ altExercise.title }}</v-list-item-title>
                </v-list-item-content>
                <div>
                  <v-btn
                    class="mr-2"
                    outlined
                    @click="onEditAltExercise(altExercise, index)"
                  >
                    <span class="text-sm">Edit</span>
                  </v-btn>
                  <v-btn
                    outlined
                    @click="onDeleteAltExercise(altExercise)"
                    :loading="altExerciseLoading === altExercise.id"
                  >
                    <span class="text-sm">Delete</span>
                  </v-btn>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </v-card>
        <span class="error--text ml-3 text-caption">{{
          form.$getError('alternative_exercises')?.[0]
        }}</span>
      </div>
    </div>

    <div v-if="showAltExerciseForm">
      <AlternativeExerciseForm
        :show="showAltExerciseForm"
        :currentAltExercise="altExerciseForm"
        :isEdit="isEdit"
        :loading="addAltExerciseLoading || updateAltExerciseLoading"
        @onSave="onAddAltExercise"
        @onUpdate="onUpdateAltExercise"
        @onCancel="cancelAltExercise"
      />
    </div>
  </div>
</template>
<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import ContentEditor from '@/components/ContentEditor'
import ThumbnailUploader from '@/components/base/ThumbnailUploader.vue'
import Api from '@/services/api'
import axios from 'axios'
import FileUploader from '@/components/base/FileUploader.vue'
import CategorySelectBox from '@/components/exercise/CategorySelectBox'
import AlternativeExerciseForm from '@/components/exercise/AlternativeExerciseForm'

export default {
  name: 'ExerciseItemIndex',
  components: {
    AppBarNavIcon,
    ContentEditor,
    ThumbnailUploader,
    FileUploader,
    CategorySelectBox,
    AlternativeExerciseForm,
  },
  computed: {
    ...mapState({
      exercise: (state) => state.exercise.selectedExercise,
      exerciseCategories: (state) => state.exerciseCategory.categories,
      addAltExerciseLoading: (state) => state.exercise.addAltExerciseLoading,
      updateAltExerciseLoading: (state) =>
        state.exercise.updateAltExerciseLoading,
    }),
    ...mapState('exerciseCategory', {
      categoriesList: (state) => state.categoryList,
    }),
    exerciseId() {
      return parseInt(this.$route.params.id)
    },
  },
  data() {
    return {
      submitting: false,
      form: new Form({
        category_id: null,
        title: null,
        content: null,
        video_thumb_nail_id: null,
        thumb_nail_id: null,
        is_free: 1,
        video: null,
        thumbnail: null,
        video_from_s3: null,
        thumb_nail_from_s3: null,
      }),
      thumbnailUrl: '',
      showAltExerciseForm: false,
      altExerciseForm: new Form({
        title: null,
        link: null,
        thumb_nail: null,
      }),
      isEdit: false,
      exerciseLoading: false,
      altExerciseLoading: null,
    }
  },
  async mounted() {
    await this.getExerciseCategories()

    const exerciseId = this.$route.params.id

    await this.getExerciseDetails(exerciseId)

    this.resetForm()
  },
  methods: {
    ...mapActions({
      getExerciseDetails: 'exercise/getExerciseDetails',
      getExerciseCategories: 'exerciseCategory/getExerciseCategories',
      addAltExercise: 'exercise/addAltExercise',
      updateAltExercise: 'exercise/updateAltExercise',
      deleteAltExercise: 'exercise/deleteAltExercise',
    }),
    resetForm() {
      this.thumbnailUrl = this.exercise.data.thumb_nail?.url
      this.form.title = this.exercise.data.title
      this.form.is_free = this.exercise.data.is_free
      this.form.content = this.exercise.data.content
      this.form.video = this.exercise.data.video
      this.form.thumbnail = this.exercise.data.thumb_nail
      this.form.thumb_nail_id = this.exercise.data.thumb_nail?.id
      this.form.video_thumb_nail_id = this.exercise.data.video_thumb_nail?.id
      this.form.categories = this.exercise.data.categories
      this.form.alternative_exercises = this.exercise.data.alternative_exercises
      this.form.video_from_s3 = null
      this.form.thumb_nail_from_s3 = null
    },
    async uploadFile(file) {
      const response = await Api.post('/s3-upload-signed-url/generate', {
        file_name: file.name,
        mime_type: file.type,
        size: file.size,
      })

      const { id, url } = response.data.data

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      return id
    },

    onEditAltExercise(altExercise, index) {
      this.isEdit = true

      this.altExerciseForm.id = altExercise.id
      this.altExerciseForm.title = altExercise.title
      this.altExerciseForm.link = altExercise.link
      this.altExerciseForm.thumb_nail = altExercise.thumb_nail

      this.editIndex = index
      this.showAltExerciseForm = true
    },

    async onAddAltExercise(altExercise) {
      this.$store.commit('exercise/setAddAltExerciseLoading', {
        loading: true,
      })

      try {
        if (altExercise.thumb_nail instanceof File) {
          altExercise.thumb_nail_id = await this.uploadFile(
            altExercise.thumb_nail
          )
        }

        this.addAltExercise({
          exercise_id: this.$route.params.id,
          data: altExercise,
        }).then(() => {
          this.showAltExerciseForm = false
          this.cancelAltExercise()
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.altExerciseForm.$setErrors(e.response?.data?.errors)
        }
      }
    },

    async onUpdateAltExercise(altExercise) {
      let payload = {
        id: altExercise.id,
        title: altExercise.title,
        link: altExercise.link,
      }

      this.$store.commit('exercise/setUpdateAltExerciseLoading', {
        loading: true,
      })
      try {
        if (altExercise.thumb_nail instanceof File) {
          payload.thumb_nail_id = await this.uploadFile(altExercise.thumb_nail)
        }
      } catch (e) {
        if (e.response?.data?.errors) {
          this.altExerciseForm.$setErrors(e.response?.data?.errors)
        }
        return
      }

      this.updateAltExercise({
        exercise_id: this.$route.params.id,
        alt_exercise_id: altExercise.id,
        data: payload,
      }).then(() => {
        this.showAltExerciseForm = false
        this.cancelAltExercise()
      })
    },

    async onDeleteAltExercise(altExercise) {
      this.altExerciseLoading = altExercise.id
      await this.deleteAltExercise({
        exercise_id: this.$route.params.id,
        alt_exercise_id: altExercise.id,
      })
      this.altExerciseLoading = null
    },

    cancelAltExercise() {
      this.editIndex = null
      this.isEdit = false
      this.resetCurrentAltExercise()
      this.showAltExerciseForm = false
    },

    resetCurrentAltExercise() {
      delete this.altExerciseForm.id
      this.altExerciseForm.$reset()
    },

    async submit() {
      this.submitting = true

      let payload = {
        title: this.form.title,
        content: this.form.content
          ? this.form.content === '<p></p>'
            ? null
            : this.form.content
          : null,
        is_free: this.form.is_free,
        categories: this.form.categories,
      }

      try {
        if (this.form.video_from_s3) {
          payload.video_from_s3 = this.form.video_from_s3
        } else if (this.form.video instanceof File) {
          const video_id = await this.uploadFile(this.form.video)
          payload = { ...payload, video_id } // Use spread operator to add video_id
        }
        if (this.form.thumb_nail_from_s3) {
          payload.thumb_nail_from_s3 = this.form.thumb_nail_from_s3
        } else if (this.form.thumbnail instanceof File) {
          const thumb_nail_id = await this.uploadFile(this.form.thumbnail)
          payload = { ...payload, thumb_nail_id } // Use spread operator to add thumb_nail_id
        }
        const { data } = await Api.post(
          `/exercise/videos/${this.exerciseId}?_method=put`,
          payload
        )
        this.$store.commit('exercise/setSelectedExercise', {
          loading: false,
          data: data.data,
        })
        this.resetForm()
        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Exercise successfully updated.',
        })
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.thumbnail-uploader {
  height: 100%;
  border-radius: 8px;
}

.thumbnail-uploader {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #e6e6e9;
  justify-content: space-between;
}
</style>
