var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"873","scrollable":""},on:{"input":(newValue) => _vm.$emit('input', newValue)}},[_c('v-card',[_c('v-card-title',{staticClass:"font-semibold text-2xl border-b"},[_vm._v(" Week "+_vm._s(_vm.form.week)+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"outlined":"","color":"primary","large":""},on:{"click":_vm.addDay}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',{staticClass:"text-sm font-semibold"},[_vm._v("Day")])],1)],1),_c('v-expansion-panels',{staticClass:"mt-4",attrs:{"accordion":"","flat":""},model:{value:(_vm.expandedPanels),callback:function ($$v) {_vm.expandedPanels=$$v},expression:"expandedPanels"}},[_c('draggable',_vm._b({staticStyle:{"width":"100%"},attrs:{"tag":"div","disabled":_vm.expandedPanels !== undefined},on:{"start":function($event){_vm.drag = true},"end":_vm.onDragEnd},model:{value:(_vm.form.daily_exercises),callback:function ($$v) {_vm.$set(_vm.form, "daily_exercises", $$v)},expression:"form.daily_exercises"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"tag":"div","type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.form.daily_exercises),function(day,i){return _c('v-expansion-panel',{key:`daily_exercises_${i}`,class:`daily-exercises ${
                _vm.checkError(_vm.form.$errors, i) ? 'has-error' : ''
              }`},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',[_c('v-menu',{attrs:{"offset-y":"","offset-x":"","bottom":"","left":"","max-width":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({},'button',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background":"white"}},[_c('p',{staticClass:"mb-0 py-2 px-4 text-xs font-medium clickable",on:{"click":function($event){return _vm.initDelete(i)}}},[_vm._v(" Delete ")]),_c('p',{staticClass:"mb-0 py-2 px-4 text-xs font-medium clickable",on:{"click":function($event){return _vm.duplicateDay(i)}}},[_vm._v(" Duplicate ")])])])],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"daily-exercises-item__title"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"height":"20","width":"12","src":require("@/assets/icons/sort-icon.svg")}})],1),_c('div',[_c('p',{staticClass:"ma-0 mb-2 text-43435A text-base"},[_vm._v(" Day "+_vm._s(day.day)+" ")]),_c('p',{staticClass:"ma-0 text-43435A text-xs"},[(day.contents.length)?_c('span',[_vm._v(_vm._s(day.contents.length)+" exercise"+_vm._s(day.contents.length > 1 ? 's' : ''))]):_c('span',[_vm._v("No exercises")])])])])]),_c('day-form',{attrs:{"id":i,"exercise-categories":_vm.exerciseCategories,"day":_vm.form.daily_exercises[i].day,"thumbnail":_vm.form.daily_exercises[i].thumb_nail,"contents":_vm.form.daily_exercises[i].contents,"categoryId":_vm.form.daily_exercises[i].category_id,"errors":_vm.form.$errors,"dailyExercisId":_vm.form.daily_exercises[i].id},on:{"input:thumbnail":(thumbnail) =>
                    (_vm.form.daily_exercises[i].thumb_nail = thumbnail),"input:day":(day) => (_vm.form.daily_exercises[i].day = parseInt(day)),"input:categoryId":(categoryId) =>
                    _vm.updateExerciseItemField(
                      i,
                      'category_id',
                      parseInt(categoryId)
                    ),"input:customName":(customName) =>
                    _vm.updateExerciseItemField(i, 'custom_name', customName),"input:contents":({ contents, extraField = '' }) =>
                    _vm.updateExerciseItemField(
                      i,
                      'contents',
                      contents,
                      extraField
                    )}})],1)}),1)],1)],1),(!_vm.form.daily_exercises.length)?_c('div',{staticClass:"pa-5",staticStyle:{"border":"2px dashed rgba(59, 85, 206, 0.8)","border-radius":"8px"}},[_c('p',{staticClass:"ma-0 text-center"},[_vm._v("Please add at least 1 day.")])]):_vm._e(),(_vm.form.$errors.daily_exercises)?_c('div',{staticClass:"v-text-field__details mt-2 ml-3"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},_vm._l((_vm.form.$errors.daily_exercises),function(error){return _c('div',{key:`content-errors-${error}`,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])]):_vm._e()],1),_c('v-card-actions',[_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"block":"","color":"primary","large":""},on:{"click":_vm.submit}},[_c('span',{staticClass:"text-sm"},[_vm._v("Update Week")])])],1),_c('v-col',[_c('v-btn',{staticClass:"text-sm",attrs:{"block":"","color":"primary","outlined":"","large":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('span',{staticClass:"text-sm"},[_vm._v("Cancel")])])],1)],1)],1),_c('ConfirmModal',{attrs:{"loading":false,"title":"Duplicate Day","message":"Are you sure you want to duplicate day?"},on:{"cancel":function($event){_vm.showDuplicateModal = false},"confirm":_vm.handleDuplicateConfirm},model:{value:(_vm.showDuplicateModal),callback:function ($$v) {_vm.showDuplicateModal=$$v},expression:"showDuplicateModal"}}),_c('ConfirmModal',{attrs:{"loading":false,"title":"Delete Day","message":"This actions is irreversible. <br/> Are you sure you want to delete the day ?"},on:{"cancel":function($event){_vm.showDeleteModal = false},"confirm":_vm.handleDeleteConfirm},model:{value:(_vm.showDeleteModal),callback:function ($$v) {_vm.showDeleteModal=$$v},expression:"showDeleteModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }