<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Number of Sets"
          :value="noOfSets"
          @input="$emit('input:no-of-sets', $event)"
          class="text-sm"
          :error-messages="
            errors[
              `daily_exercises.${dayIndex}.contents.${contentIndex}.number_of_sets`
            ]
          "
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Number of Reps"
          :value="noOfReps"
          @input="$emit('input:no-of-reps', $event)"
          :error-messages="
            errors[
              `daily_exercises.${dayIndex}.contents.${contentIndex}.number_of_reps`
            ]
          "
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Super Sets Label"
          :value="superSetLabel"
          @input="$emit('input:super-set-label', $event)"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Intensity Technique"
          :value="intensityTechnique"
          @input="$emit('input:intensity-technique', $event)"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Rest"
          :value="rest"
          @input="$emit('input:rest', $event)"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field
          outlined
          required
          label="Tempo"
          :value="tempo"
          @input="$emit('input:tempo', $event)"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-combobox
          outlined
          multiple
          :loading="loadingAlternativeExercises"
          :items="alternativeExercisesOptions.data"
          item-text="title"
          item-value="id"
          label="Alternative Exercises"
          hide-details="auto"
          :search-input.sync="searchInput"
          :value="alternativeExercises"
          @input="$emit('input:alternativeExercises', $event)"
        >
          <template v-slot:append-item>
            <div v-intersect="endIntersect" />
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import Exercise from '@/models/Exercise'
import { debounce, each, find } from 'lodash'

export default {
  name: 'ExerciseForm',
  props: {
    dayIndex: [Number, String],
    contentIndex: [Number, String],
    id: [Number, String],
    dailyExerciseId: [Number, String],
    noOfSets: [Number, String],
    noOfReps: [Number, String],
    alternativeExercises: Array,
    exerciseCategories: Array,
    exerciseVideo: Object,
    intensityTechnique: String,
    superSetLabel: String,
    rest: String,
    tempo: String,
    errors: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchInput: null,
      loadingAlternativeExercises: true,
      alternativeExercisesOptions: {
        data: [],
      },
    }
  },
  watch: {
    searchInput(val) {
      this.entries = this.throttleSearch(val)
    },
  },
  async mounted() {
    await this.getAlternativeExercisesOptions()
  },
  methods: {
    async getAlternativeExercisesOptions(page = 1) {
      const query = Exercise.page(page).include(['thumb_nail'])

      if (this.searchInput) {
        query.where('search', this.searchInput)
      }

      const response = await query.get()

      each(response.data, (exercise) => {
        const exist = find(this.alternativeExercisesOptions.data, {
          id: exercise.id,
        })
        if (!exist) {
          this.alternativeExercisesOptions.data.push(new Exercise(exercise))
        }
      })

      this.alternativeExercisesOptions.meta = response.meta
      this.alternativeExercisesOptions.links = response.links

      this.loadingAlternativeExercises = false
    },
    throttleSearch: debounce(function () {
      this.getAlternativeExercisesOptions()
    }, 1000),
    async endIntersect(entries, observer, isIntersecting) {
      if (
        isIntersecting &&
        this.alternativeExercisesOptions?.meta?.current_page <
          this.alternativeExercisesOptions?.meta?.last_page
      ) {
        await this.getAlternativeExercisesOptions(
          this.alternativeExercisesOptions?.meta?.current_page + 1
        )
      }
    },
  },
}
</script>

<!-- v-model="
            currentDay.contents[findIndex(exercise)].alternative_exercises
          " -->

<!-- if (
        this.altExercisesStore?.meta?.current_page <
        this.altExercisesStore?.meta?.last_page
      ) {
        this.getExercises({
          loadMore: true,
          type: 'alt',
          page: this.altExercisesStore.meta.current_page + 1,
        })
      } -->

<!--  -->
