<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'users' }">
          Users
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-container class="ma-4" fluid>
      <div v-if="form">
        <div class="display-2 font-weight-bold my-6 text-capitalize">
          {{ displayName }}
        </div>

        <v-tabs hide-slider class="user-info-tabs">
          <v-tab v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item>
            <v-alert
              dense
              outlined
              type="warning"
              color="orange darken-3"
              max-width="400px"
              class="mx-auto"
              v-if="!!user.blocked_at"
            >
              This account is <strong>disabled</strong>.
            </v-alert>

            <avatar-form
              :user="user"
              @change="avatarChange"
              @avatarRemove="userAvatarRemoved"
              :avatarLoading="avatarLoading"
              class="my-4"
            />

            <v-form ref="form" v-if="form">
              <v-divider class="my-4"></v-divider>
              <v-row>
                <v-col cols="12" md="6">
                  <h3 class="py-5 primary--text">User Information</h3>

                  <label class="text-field-label">Name</label>
                  <v-text-field
                    flat
                    solo
                    required
                    readonly
                    class="mt-2"
                    v-model="form.full_name"
                    :error-messages="form.$getError('full_name')"
                    :loading="loading"
                  ></v-text-field>

                  <label class="text-field-label">Email</label>
                  <v-text-field
                    flat
                    solo
                    required
                    readonly
                    class="mt-2"
                    v-model="form.email"
                    :error-messages="form.$getError('email')"
                    :loading="loading"
                  >
                    <v-tooltip slot="append" top v-if="user.email">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="success"
                          v-on="on"
                          v-if="user.email_verified"
                          >{{ icons.check }}</v-icon
                        >
                        <v-icon color="warning" v-on="on" v-else>{{
                          icons.alert
                        }}</v-icon>
                      </template>
                      <span v-if="user.email_verified">Email Verified</span>
                      <span v-else>Unverified email</span>
                    </v-tooltip>
                  </v-text-field>

                  <!-- <label class="text-field-label">Phone number</label> -->
                  <!-- <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    v-model="form.phone_number"
                    :error-messages="form.$getError('phone_number')"
                    :loading="loading"
                  >
                    <v-tooltip slot="append" top v-if="user.phone_number">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="success"
                          v-on="on"
                          v-if="user.phone_number_verified"
                          >{{ icons.check }}</v-icon
                        >
                        <v-icon color="warning" v-on="on" v-else>{{
                          icons.alert
                        }}</v-icon>
                      </template>
                      <span v-if="user.phone_verified"
                        >Phone Number Verified</span
                      >
                      <span v-else>Unverified Phone Number</span>
                    </v-tooltip>
                  </v-text-field> -->
                  <v-checkbox
                    :input-value="user.blocked_at"
                    label="Disable user account"
                    color="orange darken-3"
                    class="mt-0"
                    @mousedown="showModal = true"
                  ></v-checkbox>

                  <!-- <v-btn
                    color="primary"
                    class="mt-4 px-6"
                    block
                    @click="updateUserDetails"
                    :loading="form.$busy"
                  >
                    Update
                  </v-btn> -->
                </v-col>

                <v-col cols="12" md="6">
                  <h3 class="py-5 primary--text">Subscription Details</h3>

                  <v-card class="pa-4" outlined>
                    <v-row>
                      <v-col cols="12" md="6">
                        <strong>Subscription Status:</strong>
                        <p>{{ subscriptionStatus }}</p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <strong>Subscription Type:</strong>
                        <p>{{ subscriptionType }}</p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <strong>Subscription Start Date:</strong>
                        <p>
                          {{
                            user?.subscription &&
                            !user?.subscription?.is_expired
                              ? formatDate(user.subscription?.created_at)
                              : 'N/A'
                          }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <strong>Subscription End Date:</strong>
                        <p>
                          {{
                            user?.subscription &&
                            !user?.subscription?.is_expired
                              ? formatDate(user.subscription?.expires_at)
                              : 'N/A'
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-container>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <ConfirmModal
      v-model="showModal"
      title="Disable Account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    />
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AvatarForm from './components/AvatarForm'
// import TestNotifications from '@/components/TestNotifications'
import ConfirmModal from '@/components/modals/ConfirmModal'

import Form from '@/utils/form'

export default {
  name: 'UserDetails',

  components: {
    AvatarForm,
    AppBarNavIcon,
    // TestNotifications,
    ConfirmModal,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      tabItems: [
        { tab: 'Info' }, // { tab: 'Chats' }
      ],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      showModal: false,
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userDetails,
      authUser: (state) => state.auth.user,
    }),
    displayName() {
      return this.form.full_name || this.form.email
    },
    disableMessage() {
      const action = this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    },

    subscriptionStatus() {
      const subscription = this.user.subscription

      if (!subscription) {
        return 'Free'
      }

      return subscription.is_expired ? 'Expired' : 'Subscribed'
    },

    subscriptionType() {
      const productId = this.user?.subscription?.product_id
      if (!productId || this.user?.subscription.is_expired) {
        return 'N/A'
      }
      return productId.includes('monthly') ? 'Monthly' : 'Yearly'
    },
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser',
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      setSelectedUserAvatar: 'user/setSelectedUserAvatar',
      userAvatarRemoved: 'user/userAvatarRemoved',
    }),

    async getUser() {
      this.loading = true
      await this.getUserDetails(this.$route.params.id)
      this.form = new Form(this.user)
      this.loading = false
    },

    async avatarChange(image) {
      this.avatarLoading = true
      let data = new FormData()
      data.append('user_id', this.user.id)
      data.append('avatar', image)
      await this.changeAvatar(data)
      this.avatarLoading = false
      this.showSnackbar('Avatar successfully updated!', 'success')
    },

    async updateUserDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateUser(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('User details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteUser() {
      this.form.$busy = true
      this.user.delete().then(() => {
        this.form.$busy = false
        this.deleteDialog = false
        this.showSnackbar('User deleted successfully!')
        this.$router.replace('/users')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
    blockAccount(value) {
      this.form.$busy = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'
      this.blockUser({
        id: this.user.id,
        action,
      })
        .then(() => {
          this.form.$busy = false
          this.showModal = false
          this.showSnackbar(`User successfully ${action}!`, 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },
    formatDate(dateString) {
      if (!dateString) return null
      const date = new Date(dateString)
      return date.toLocaleDateString('en-GB')
    },
  },

  created() {
    this.getUser()
  },

  destroyed() {
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getUser()
    },
  },
}
</script>

<style lang="scss" scoped></style>
