<script>
import { mdiDotsVertical, mdiPlus } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import DayForm from './DayForm.vue'
import Api from '@/services/api'
import axios from 'axios'
import Form from '@/utils/form'
import draggable from 'vuedraggable'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'WeeklyExerciseFormModal',
  components: {
    DayForm,
    draggable,
    ConfirmModal,
  },
  props: {
    value: Boolean,
    programId: Number,
  },
  computed: {
    ...mapState({
      exerciseCategories: (state) => state.exerciseCategory.categories,
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDotsVertical,
      },
      model: null,
      search: null,
      isLoading: false,
      form: new Form({
        week: null,
        id: null,
        daily_exercises: [],
      }),
      drag: false,
      showDuplicateModal: false,
      showDeleteModal: false,
      selectedIndex: -1,
      expandedPanels: undefined,
    }
  },
  mounted() {
    this.getExerciseCategories()
  },
  methods: {
    ...mapActions({
      getExerciseCategories: 'exerciseCategory/getExerciseCategories',
    }),
    setFormData(formData) {
      this.form.id = formData.id
      this.form.week = formData.week
      this.form.daily_exercises = formData.daily_exercises
    },
    addDay() {
      let newDailyExercises = [...this.form.daily_exercises]

      newDailyExercises.push({
        day: this.form.daily_exercises.length + 1,
        custom_name: null,
        category_id: null,
        thumb_nail_id: null,
        thumb_nail: null,
        contents: [],
      })

      this.form.daily_exercises = newDailyExercises

      this.form.$clearError('daily_exercises')
    },
    onRemoveDay(index) {
      this.form.daily_exercises = [...this.form.daily_exercises]
        .filter((item, idx) => idx !== index)
        .map((item, idx) => {
          return {
            ...item,
            day: idx + 1,
          }
        })

      this.form.$clearError('daily_exercises')
    },
    duplicateDay(index) {
      const item = this.form.daily_exercises.find((item, idx) => idx === index)

      let newDailyExercises = [...this.form.daily_exercises]

      newDailyExercises.push({
        ...item,
        id: null,
        day: this.form.daily_exercises.length + 1,
        thumb_nail_id: item.thumb_nail?.id,
        contents: item.contents.map((c) => ({ ...c, id: null })),
      })

      this.form.daily_exercises = newDailyExercises
    },
    async uploadFile(file) {
      const response = await Api.post('/s3-upload-signed-url/generate', {
        file_name: file.name,
        mime_type: file.type,
        size: file.size,
      })

      const { id, url } = response.data.data

      await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      return id
    },
    async submit() {
      this.isLoading = true

      try {
        let data = JSON.parse(JSON.stringify(this.form.$data()))

        data.daily_exercises = await Promise.all(
          data.daily_exercises.map(async (dailyExercise, index) => {
            if (
              this.form.$data().daily_exercises[index].thumb_nail instanceof
              File
            ) {
              dailyExercise.thumb_nail_id = await this.uploadFile(
                this.form.$data().daily_exercises[index].thumb_nail
              )

              delete dailyExercise.thumb_nail
            }

            dailyExercise.contents = dailyExercise.contents.map((content) => {
              return {
                ...content,
                alternative_exercises: content.alternative_exercises?.map(
                  (i) => i.id
                ),
              }
            })

            return dailyExercise
          })
        )

        this.form.id
          ? await Api.put(
              `admin/programs/${this.programId}/weekly-exercises/${this.form.id}`,
              data
            )
          : await Api.post(
              `admin/programs/${this.programId}/weekly-exercises`,
              data
            )

        this.$store.commit('setSnackbar', {
          show: true,
          color: 'success',
          text: 'Weekly exercise updated successfully',
        })
        this.$emit('reload')
        this.$emit('input', false)
      } catch (e) {
        if (e.response?.data?.errors) {
          this.form.$setErrors(e.response?.data?.errors)
        }
      } finally {
        this.isLoading = false
      }
    },
    updateExerciseItemField(index, field, value, extraField = '') {
      this.form.daily_exercises[index][field] = value

      this.form.$clearError(`daily_exercises.${index}.${field}`)
      this.form.$clearError(`daily_exercises.${index}.${field}${extraField}`)
    },
    onDragEnd() {
      this.drag = false
      this.form.daily_exercises = this.form.daily_exercises.map(
        (item, day) => ({
          ...item,
          day: day + 1,
        })
      )
    },
    handleDuplicateConfirm() {
      this.duplicateDay(this.selectedIndex)
      this.selectedIndex = -1
      this.showDuplicateModal = false
    },
    handleDeleteConfirm() {
      this.onRemoveDay(this.selectedIndex)
      this.selectedIndex = -1
      this.showDeleteModal = false
    },
    initDuplicate(i) {
      this.selectedIndex = i
      this.showDuplicateModal = true
    },
    initDelete(i) {
      this.selectedIndex = i
      this.showDeleteModal = true
    },
    checkError(errors, i) {
      return Object.keys(errors).some((key) =>
        key.includes(`daily_exercises.${i}`)
      )
    },
  },
}
</script>

<template>
  <v-dialog
    v-bind:value="value"
    v-on:input="(newValue) => $emit('input', newValue)"
    width="873"
    scrollable
  >
    <v-card>
      <v-card-title class="font-semibold text-2xl border-b">
        Week {{ form.week }}
      </v-card-title>

      <v-card-text>
        <div class="d-flex justify-end">
          <v-btn outlined color="primary" large @click="addDay">
            <v-icon color="primary">
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="text-sm font-semibold">Day</span>
          </v-btn>
        </div>

        <v-expansion-panels
          accordion
          flat
          class="mt-4"
          v-model="expandedPanels"
        >
          <draggable
            tag="div"
            :disabled="expandedPanels !== undefined"
            v-model="form.daily_exercises"
            v-bind="dragOptions"
            @start="drag = true"
            @end="onDragEnd"
            style="width: 100%"
          >
            <transition-group
              tag="div"
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <v-expansion-panel
                v-for="(day, i) in form.daily_exercises"
                :key="`daily_exercises_${i}`"
                :class="`daily-exercises ${
                  checkError(form.$errors, i) ? 'has-error' : ''
                }`"
              >
                <v-expansion-panel-header>
                  <div class="daily-exercises-item__title">
                    <div class="mr-5">
                      <v-img
                        height="20"
                        width="12"
                        src="@/assets/icons/sort-icon.svg"
                      />
                    </div>

                    <div>
                      <p class="ma-0 mb-2 text-43435A text-base">
                        Day {{ day.day }}
                      </p>
                      <p class="ma-0 text-43435A text-xs">
                        <span v-if="day.contents.length"
                          >{{ day.contents.length }} exercise{{
                            day.contents.length > 1 ? 's' : ''
                          }}</span
                        >
                        <span v-else>No exercises</span>
                      </p>
                    </div>
                  </div>

                  <template v-slot:actions>
                    <div>
                      <v-menu offset-y offset-x bottom left max-width="100">
                        <template v-slot:activator="{ on, attrs }">
                          <button v-bind="attrs" v-on="on">
                            <v-icon>
                              {{ icons.mdiDotsVertical }}
                            </v-icon>
                          </button>
                        </template>

                        <div style="background: white">
                          <p
                            class="mb-0 py-2 px-4 text-xs font-medium clickable"
                            @click="initDelete(i)"
                          >
                            Delete
                          </p>
                          <p
                            class="mb-0 py-2 px-4 text-xs font-medium clickable"
                            @click="duplicateDay(i)"
                          >
                            Duplicate
                          </p>
                        </div>
                      </v-menu>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <day-form
                  :id="i"
                  :exercise-categories="exerciseCategories"
                  :day="form.daily_exercises[i].day"
                  :thumbnail="form.daily_exercises[i].thumb_nail"
                  :contents="form.daily_exercises[i].contents"
                  :categoryId="form.daily_exercises[i].category_id"
                  :errors="form.$errors"
                  :dailyExercisId="form.daily_exercises[i].id"
                  @input:thumbnail="
                    (thumbnail) =>
                      (form.daily_exercises[i].thumb_nail = thumbnail)
                  "
                  @input:day="
                    (day) => (form.daily_exercises[i].day = parseInt(day))
                  "
                  @input:categoryId="
                    (categoryId) =>
                      updateExerciseItemField(
                        i,
                        'category_id',
                        parseInt(categoryId)
                      )
                  "
                  @input:customName="
                    (customName) =>
                      updateExerciseItemField(i, 'custom_name', customName)
                  "
                  @input:contents="
                    ({ contents, extraField = '' }) =>
                      updateExerciseItemField(
                        i,
                        'contents',
                        contents,
                        extraField
                      )
                  "
                />
              </v-expansion-panel>
            </transition-group>
          </draggable>
        </v-expansion-panels>

        <div
          class="pa-5"
          v-if="!form.daily_exercises.length"
          style="border: 2px dashed rgba(59, 85, 206, 0.8); border-radius: 8px"
        >
          <p class="ma-0 text-center">Please add at least 1 day.</p>
        </div>

        <div
          class="v-text-field__details mt-2 ml-3"
          v-if="form.$errors.daily_exercises"
        >
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div
                v-for="error in form.$errors.daily_exercises"
                :key="`content-errors-${error}`"
                class="v-messages__message"
              >
                {{ error }}
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            block
            color="primary"
            large
            @click="submit"
          >
            <span class="text-sm">Update Week</span>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            block
            color="primary"
            class="text-sm"
            outlined
            large
            :disabled="isLoading"
            @click="$emit('input', false)"
          >
            <span class="text-sm">Cancel</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>

    <ConfirmModal
      :loading="false"
      title="Duplicate Day"
      message="Are you sure you want to duplicate day?"
      v-model="showDuplicateModal"
      @cancel="showDuplicateModal = false"
      @confirm="handleDuplicateConfirm"
    />

    <ConfirmModal
      :loading="false"
      title="Delete Day"
      message="This actions is irreversible. <br/> Are you sure you want to delete the day ?"
      v-model="showDeleteModal"
      @cancel="showDeleteModal = false"
      @confirm="handleDeleteConfirm"
    />
  </v-dialog>
</template>

<style scoped lang="scss">
.has-error {
  border: 1px solid #fa4856 !important;
}

.daily-exercises {
  border: 1px solid #e6e6e9;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5 !important;
  background: #3b55ce !important;
}

.daily-exercises-item__title {
  display: flex;
  align-items: center;
}
</style>
